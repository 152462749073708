/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./normalize.css"
import "./layout.scss"

import moodboardLogo from "../images/moodboard-logo-white.svg"

import { stack as Menu } from './react-burger-menu/BurgerMenu.js'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  function buildWave(w, h) {

    var m = 0.512286623256592433;

    return [
      'M', 0, h * 0.625,
      'c',  0.25 * h * m, 0,
      0.25 * h * (1 - m), -0.25 * h,
      0.25 * h, -0.25 * h,
      's', 0.25 * h * (1 - m), 0.25 * h,
      0.25 * h, 0.25 * h,
      's', 0.25 * h * (1 - m), -0.25 * h,
      0.25 * h, -0.25 * h,
      's', 0.25 * h * (1 - m), 0.25 * h,
      0.25 * h, 0.25 * h,
      's', 0.25 * h * (1 - m), -0.25 * h,
      0.25 * h, -0.25 * h,
      's', 0.25 * h * (1 - m), 0.25 * h,
      0.25 * h, 0.25 * h,
      's', 0.25 * h * (1 - m), -0.25 * h,
      0.25 * h, -0.25 * h,
      's', 0.25 * h * (1 - m), 0.25 * h,
      0.25 * h, 0.25 * h,
      's', 0.25 * h * (1 - m), -0.25 * h,
      0.25 * h, -0.25 * h,
      's', 0.25 * h * (1 - m), 0.25 * h,
      0.25 * h, 0.25 * h,
      's', 0.25 * h * (1 - m), -0.25 * h,
      0.25 * h, -0.25 * h,'s', 0.25 * h * (1 - m), 0.25 * h,
      0.25 * h, 0.25 * h,
      's', 0.25 * h * (1 - m), -0.25 * h,
      0.25 * h, -0.25 * h
    ].join(' ');

  }

  return (
    <>
      <Menu width={ '100%' } top>
        <img className="menu-item menu-logo" src={moodboardLogo} />
        <a id="home" className="menu-item menu-large" href="/">Home</a>
        <div className="menu-item menu-hr">
          <svg xmlns="http://www.w3.org/2000/svg"
             width="170px" height="120px"
             viewBox="5 0 170 120">
            <path id="wave2"
                d={buildWave(180, 120)}
                fill="none"
                stroke="#ffffff"
                strokeWidth="2"
                strokeLinecap="round">
            </path>
          </svg>
        </div>
        <a id="fb" target="_blank" className="menu-item menu-medium" href="https://www.facebook.com/mooodboardapp/">Facebook</a>
        <a id="ig" target="_blank" className="menu-item menu-medium" href="https://www.instagram.com/moodboardapp/">Instagram</a>
        <a id="tw" target="_blank" className="menu-item menu-medium" href="https://twitter.com/moodboard_app">Twitter</a>
        <a id="terms" className="menu-item menu-small" href="#">Terms</a>
      </Menu>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          display: `flex`,
          justifyContent: `center`,
          margin: `0 auto`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
